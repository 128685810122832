<template>
  <edit-template @confirm="handleSubmit" :confirmLoading="loading">
    <el-form class="pl-5 pt-2" ref="form" :model="formData" :rules="rules" label-position="left" label-width="120rem" >
      <template v-if="id">
        <el-form-item label="参考城市">{{ info.city_name }}</el-form-item>
        <!-- <el-form-item label="区/县">{{ info.area_name }}</el-form-item> -->
        <el-form-item label="总分计分方式">{{ info.type_name }}</el-form-item>
      </template>
      <template v-else>
        <el-form-item label="参考城市" prop="city">
          <el-cascader v-model="formData.city" :options="areaOptions"></el-cascader>
        </el-form-item>
        <!-- <el-form-item label="区/县">
          <el-select v-model="formData.area_code">
            <el-option v-for="i in qxOptions" :key="i.value" :label="i.label" :value="i.value" />
          </el-select>
        </el-form-item> -->
        <el-form-item label="总分计分方式" prop="score_type">
          <el-select v-model="formData.score_type" placeholder="请选择" @change="handleChange">
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <el-form-item label="中考体育总分" prop="physical_score">
        <el-input v-model="formData.physical_score" type="number" placeholder="请输入"></el-input>
      </el-form-item>
      <div class="z-form-title">配置中考总分</div>
      <div class="flex">
        <el-form-item label="中考满分" prop="total_score_num">
          <el-input v-model="formData.total_score_num" :disabled="formData.score_type === ''" :type="formData.score_type ? 'text' : 'number'" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item v-if="formData.score_type === 1" class="ml-3" label="对应分数值" prop="total_score_level">
          <el-input v-model="formData.total_score_level" :disabled="formData.score_type === ''" type="number" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
      <div v-if="formData.score_type === 1" class="mb-5">
        <div class="z-form-title">配置考试总分等级</div>
        <div class="mb-2">
          <template v-if="formData.exam_rank.length">当前已经已配置{{ formData.exam_rank.length }}个等级</template>
          <template v-else>暂未配置</template>
        </div>
        <el-button type="primary" @click="toggleShow(1)">{{ formData.exam_rank.length ? '点此编辑' : '添加考试总分等级' }}</el-button>
        <div class="mt-2" v-if="isExamRankError">
          <el-alert title="请配置考试总分等级" type="error" :closable="false"></el-alert>
        </div>
      </div>
      <div class="z-form-title">配置考试科目</div>
      <div class="mb-2">
        <template v-if="formData.exam_subject.length">当前已经已配置{{ formData.exam_subject.length }}个科目</template>
        <template v-else>暂未配置</template>
      </div>
      <el-button type="primary" @click="toggleShow(2)">{{ formData.exam_subject.length ? '点此编辑' : '添加考试科目' }}</el-button>
      <div class="mt-2" v-if="isSubjectError">
        <el-alert title="请配置考试科目" type="error" :closable="false"></el-alert>
      </div>
    </el-form>
    <Dialog :visible.sync="visible" width="1000rem" :title="dynamicTitle" submitText="保存" :beforeSubmit="onDynamicDialogBeforeSubmit">
      <div class="flex-center z-no-margin-bottom">
        <el-form :model="dynamicData" :rules="rules" ref="dynamicForm" label-width="110rem">
          <t-result v-if="!dynamicList.length">
            <el-button slot="footer" class="mt-2" type="primary" size="small" @click="handleAdd">开始添加</el-button>
          </t-result>
          <div class="z-dynamic-list-item" v-for="(domain, index) in dynamicList" :key="domain.id">
            <div class="z-dynamic-list-inner">
              <template v-if="dynamicType === 1">
                <el-form-item label="总分等级" :prop="`exam_rank.${index}.level`" :rules="dynamicInput">
                  <el-input v-model="domain.level" maxlength="10" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="对应分数值" :prop="`exam_rank.${index}.score`" :rules="dynamicNumber">
                  <el-input v-model="domain.score" type="number" placeholder="请输入"></el-input>
                </el-form-item>
              </template>
              <template v-else-if="dynamicType === 2">
                <el-form-item label="考试科目名称" :prop="`exam_subject.${index}.subject_id`" :rules="dynamicSubject">
                  <el-select v-model="domain.subject_id" placeholder="请选择" filterable>
                    <el-option v-for="item in subjectOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="单科满分" :prop="`exam_subject.${index}.score`" :rules="dynamicNumber">
                  <el-input v-model="domain.score" type="number" placeholder="请输入"></el-input>
                </el-form-item>
              </template>
            </div>
            <el-form-item label-width="40rem">
              <el-button icon="el-icon-delete" v-if="dynamicList.length !== 1" circle @click.prevent="handelRemove(domain)"></el-button>
              <el-button icon="el-icon-plus" circle v-if="dynamicList.length !== dynamicMaxlength && index === dynamicList.length - 1" @click="handleAdd"></el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </Dialog>
  </edit-template>
</template>

<script>
import Dialog from "@/components/common/Dialog"
import { validateNumber } from '@/utils/validate'
import { getAreaDataMixin, getSubjectDataMixin } from '@/utils/mixins'
import { transformAddOrEditData, changeOption } from '@/utils'
import { zsPost, zsGet, get_2024government } from '@/api'
export default {
	_config:{"route":[{"path":"edit","meta":{"title":"编辑"}}, {"path":"add","meta":{"title":"新增"}}]},
  mixins: [getAreaDataMixin, getSubjectDataMixin],
  components: { Dialog },
  data() {
    const ESValidateSubject =  (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择'))
      } else {
        const list = this.dynamicData.exam_subject.map(item => item.subject_id)
        let result = list.filter(item => item === value)
        if(result.length > 1) callback('考试科目名称有重复，请更正后提交')
        callback()
      }
    }
    return {
      id: '',
      qxOptions: [],
      info: { type_name: '', city_name: '', area_name: '' },
      loading: false,
      visible: false,
      isExamRankError: false,
      isSubjectError: false,
      typeOptions: [{ label: '分数', value: 0 }, { label: '等级', value: 1 }],
      dynamicType: 0, // 0 关闭弹窗 1配置等级 2配置科目
      dynamicData: { exam_rank: [], exam_subject: [] },
      dynamicInput: { required: true, message: '请输入', trigger: 'blur' },
      dynamicSubject: { required: true, validator: ESValidateSubject, trigger: 'change' },
      dynamicNumber: { required: true, validator: (_, value, callback) => validateNumber(value, callback, { min: 0, max: 10000, equalToMin: true, equalToMax: true }), trigger: 'blur' },
      formData: { city: '', area_code: '', score_type: '', physical_score: '', total_score_num:'', total_score_level: '', exam_rank: [], exam_subject: [] },
      rules: {
        city: [{ required: true, message: '请选择', trigger: 'change' }],
        score_type: [{ required: true, message: '请选择', trigger: 'change' }],
        physical_score: [{ required: true, validator: (_, value, callback) => validateNumber(value, callback, { min: 0, max: 10000, equalToMin: true, equalToMax: true }), trigger: 'blur' }],
        total_score_num: [{ required: true, message: '请输入', trigger: 'blur' }],
        total_score_level: [{ required: true, validator: (_, value, callback) => validateNumber(value, callback, { min: 0, max: 10000, equalToMin: true, equalToMax: true }), trigger: 'blur' }],
      }
    }
  },
  computed: {
    dynamicMaxlength() {
      if(this.dynamicType === 1) {
        return 200
      } else if(this.dynamicType === 2) {
        return 20
      }
      return 0
    },
    dynamicTitle() {
      if(this.dynamicType === 1) {
        return '配置考试总分等级'
      } else if(this.dynamicType === 2) {
        return '配置考试科目'
      }
      return ''
    },
    dynamicKey() {
      if(this.dynamicType === 1) {
        return 'exam_rank'
      } else if(this.dynamicType === 2) {
        return 'exam_subject'
      }
      return ''
    },
    dynamicList() {
      if(this.dynamicType === 1) {
        return this.dynamicData.exam_rank
      } else if(this.dynamicType === 2) {
        return this.dynamicData.exam_subject
      }
      return []
    }
  },
  // watch: {
  //   'formData.city'(val) {
  //     if(val) {
  //       if (this.id) return
  //       this.formData.area_code = ''
  //       this.qxOptions = []
  //       get_2024government({parent_code: val[1]}).then(res => {
  //         this.qxOptions = changeOption({ list: res, label: 'address_name', value: 'address_code' })
  //       })
  //     }
  //   }
  // },
  created() {
    if(this.$route.query.id) {
      this.id = +this.$route.query.id
    } else {
      this.getAreaData()
    }
    this.getConfig()
    this.getData()
  },
  methods: {
    getConfig() {
      this.getSubjectData()
    },
    getData() {
      if(!this.id) {
        this.dynamicData.exam_rank.push({ id: Date.now(), level:'', score: '', isAdd: 1 })
        this.dynamicData.exam_subject.push({ id: Date.now(), subject_id: '', score: '', isAdd: 1 })
        return
      }
      zsGet('/recruit-2024/exam/get-exam-conf', { id: this.id }).then(res => {
        const { city_name, area_name, score_type, city_code, area_code, physical_score, total_score_num, total_score_level, exam_rank = [], exam_subject = [] } = res
        this.info.city_name = city_name
        this.info.type_name = score_type === 1 ? '等级' : '分数'
        this.info.area_name = area_name || '-'

        this.formData.city = ['', city_code]
        this.formData.score_type = score_type
        this.formData.area_code = area_code
        this.formData.physical_score = physical_score

        if(score_type) {
          this.formData.total_score_num = total_score_level
          this.formData.total_score_level = total_score_num
          const list = exam_rank.map(i => ({ id: i.id, level: i.level, exam_id: i.exam_id, score: i.score}))
          this.formData.exam_rank = list
          this.dynamicData.exam_rank = list
        } else {
          this.formData.total_score_num = total_score_num
        }
        const list = exam_subject.map(i => ({ id: i.id, subject_id: i.subject_id, exam_id: i.exam_id, score: i.score }))
        this.formData.exam_subject = list
        this.dynamicData.exam_subject = list
      })
    },
    handleChange(val) {
      this.formData.total_score_num = ''
      this.formData.total_score_level = ''
      this.$refs['form'].clearValidate(['total_score_num'])
    },
    toggleShow(type) {
      this.visible = true
      this.dynamicType = type
      let key = this.dynamicKey
      if(this.formData[key].length) {
        this.dynamicData[key] = this.formData[key].map(i => ({...i}))
      }
    },
    handleAdd() {
      let key = this.dynamicKey
      if(this.dynamicMaxlength === this.dynamicData[key].length) {
        return this.$message.warning(`最多只能添加${this.dynamicMaxlength}条`);
      }
      if(key === 'exam_rank') {
        this.dynamicData.exam_rank.push({ id: Date.now(), level:'', score: '', isAdd: 1 })
      } else if(key === 'exam_subject') {
        this.dynamicData.exam_subject.push({ id: Date.now(), subject_id: '', score: '', isAdd: 1 })
      }

    },
    handelRemove(item) {
      let key = this.dynamicKey
      if(!this.dynamicData[key]) return
      const index = this.dynamicData[key].indexOf(item)
      if (!~index) return
      this.dynamicData[key].splice(index, 1);
    },
    onDynamicDialogBeforeSubmit(done) {
      this.$refs['dynamicForm'].validate(valid => {
        if(valid) {
          let key = this.dynamicKey
          const list = this.dynamicData[key].map(i => ({...i}))
          if(!list.length) return this.$message.warning('请配置数据')
          if(this.dynamicType === 1) {
            this.isExamRankError = false
          } else if(this.dynamicType === 2) {
            this.isSubjectError = false
          }
          this.formData[key] = list
          done()
          this.dynamicType = 0
        }
      })
    },
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if(valid) {
          const { city, score_type, total_score_num, total_score_level, exam_rank, exam_subject, ...other } = this.formData
          if(!exam_subject.length || (!exam_rank.length && score_type === 1)) {
            if(!exam_rank.length && score_type === 1) this.isExamRankError = true
            if(!exam_subject.length) this.isSubjectError = true
            return
          }

          const data = { ...other, score_type, total_score_num, city_code: city[1] }
          if(score_type) {
            data.exam_rank = transformAddOrEditData(exam_rank)
            data.total_score_num = total_score_level
            data.total_score_level = total_score_num
          }
          data.exam_subject = transformAddOrEditData(exam_subject)
          if(this.id) data.id = this.id
          console.log(data);
          const api = `/recruit-2024/exam/${this.id ? 'edit' : 'add'}-exam-conf`
          this.loading = true
          zsPost(api, data).then(() => {
            this.$message.success(`${this.id ? '编辑' : '新增'}成功`)
            this.$router.back()
          }).finally(() => this.loading = false)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
